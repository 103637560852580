import { useCsDashboard } from "@vahak/core/dist/hooks/useCsDashboard";
import { ReactNode } from "react";
import { AdminRole } from "../../../constants/adminRoles";

interface IfCanAccessProps {
    action: string;
    children?: ReactNode;
    skipPermission?: boolean;
    alsoCheck?: boolean;
    allowCsUser?: boolean;
    allowCustomer?: boolean;
    roles?: AdminRole[];
}

const IfCanAccess = ({
    action,
    children,
    skipPermission = false,
    alsoCheck = true,
    allowCsUser = false,
    allowCustomer = false,
    roles
}: IfCanAccessProps) => {
    const { checkPermission, isCsDashboardUser, checkRole } = useCsDashboard();
    const isUserPermitted = checkPermission(action) || (roles?.length && checkRole(roles));
    const csUserSkip = allowCsUser ? isCsDashboardUser : false;
    const customerSkip = allowCustomer ? !isCsDashboardUser : false;

    return (isUserPermitted || skipPermission || csUserSkip || customerSkip) && alsoCheck ? <>{children}</> : null;
};

export default IfCanAccess;
