export const generateSelectOption = <Value, Label, Id = any>(label: Label, value: Value, id?: Id) => {
    const result = {
        label: label,
        value: value,
        ...(id != undefined && {
            id
        })
    };
    return result;
};

export function numericEnumToArrayKeys<Type>(data: Type): string[] {
    return Object.keys(data as Object).filter((key: string) => isNaN(Number(key)));
}
export function numericEnumToArrayValues<Type>(data: Type): string[] {
    return Object.values(data as Object).filter((key: string) => !isNaN(Number(key)));
}
